import * as React from 'react'
import moment from 'moment'
import _ from 'lodash'
import Link from '../components/Link'
import { graphql } from 'gatsby'
import Seo from '../components/SEO'

const CaseStudy = ({ data }) => {
  const posts = data.allWpCaseStudies.nodes

  return (
    <>
      <Seo title="Case Studies" description="Pairing case studies list" />
      <div className="mx-3 md:mx-20 mt-12">
        <div className="mx-auto w-full my-8 text-center">
          <h1 className="text-6xl font-bold font-display  ">
            Case <span className="underline-highlight">Studies</span>
          </h1>
          <p className="text-center w-full mx-auto max-w-2xl font-paragraph text-2xl">
            Welcome to the Pairing case studies. We are building in public - so
            you learn copy, adapt and do it even better. We are creators. We
            cannot help it. We asked ourselves: what if we share the whole
            software creation process from A - Z. Follow us on the journey of
            building our own app and realasing it to the public.
          </p>
        </div>

        <div className="flex flex-wrap justify-center">
          {!_.isEmpty(posts) || !posts ? (
            posts.map((post) => (
              <Link
                to={`/casestudies/${post.slug}`}
                className="max-w-sm p-6 bg-gray-50 dark:bg-dark_bg-lighter m-4 shadow-md border border-gray-100 dark:border-dark_bg-normal rounded-md cursor-pointer"
                key={post.id}
              >
                <img
                  className="h-44 object-cover w-full rounded-t-md md:h-44"
                  src={post.jetpack_featured_media_url}
                  alt="Blog featured"
                />
                <h3 className="text-xl  font-bold my-4 font-display">
                  {post.title.rendered}
                </h3>
               
              </Link>
            ))
          ) : (
            <div class="flex mx-auto items-center justify-center space-x-2 animate-pulse">
              <div class="w-8 h-8 bg-blue-400 rounded-full"></div>
              <div class="w-8 h-8 bg-blue-400 rounded-full"></div>
              <div class="w-8 h-8 bg-blue-400 rounded-full"></div>
              <div class="w-8 h-8 bg-blue-400 rounded-full"></div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query {
    allWpCaseStudies {
      nodes {
        id
        title {
          rendered
        }
        date
        jetpack_featured_media_url
        type
        slug
        content {
          rendered
        }
        categories
      }
    }
  }
`
